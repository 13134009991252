<template>
  <mds-row class="page-not-found">
    <mds-col cols="3">
      <mds-empty-state
        size="large"
        title="404"
        message="The page you are looking for was not found."
      >
        <template #mds-empty-state-icon>
          <img
            src="https://designsystem.morningstar.com/images/404-face.svg"
            role="presentation"
          >
        </template>
        <template #mds-empty-state-actions>
          <mds-button
            size="medium"
            variation="primary"
            text="Go Back"
            @click="$router.go(-1)"
          />
        </template>
      </mds-empty-state>
    </mds-col>
  </mds-row>
</template>

<script>
import MdsEmptyState from '@mds/empty-state';
import { MdsButton } from '@mds/button';

export default {
  name: 'EmptyStateSection',
  components: {
    MdsEmptyState,
    MdsButton,
  },
};
</script>

<style lang="scss" scoped>
.page-not-found {
  display: flex;
  justify-content: center;
  margin: $mds-space-8-x auto;
}
</style>
