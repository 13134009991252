<template>
  <mds-layout-grid>
    <empty-state-section />
  </mds-layout-grid>
</template>

<script>
import EmptyStateSection from '@/components/not-found/empty-state/EmptyStateSection';

export default {
  name: 'PageNotFound',
  components: {
    EmptyStateSection,
  },
};
</script>
